@_badge-topic-color-text:              @ax-color-topic-80;
@_badge-topic-color-bg:                @ax-color-topic-40;
@_badge-path-color-text:               @ax-color-topic-80;
@_badge-path-color-bg:                 @ax-color-topic-40;
@_badge-certification-color-text:      @ax-color-certification-80;
@_badge-certification-color-bg:        @ax-color-certification-40;
@_badge-exam-color-text:               @ax-assessment-color-90;
@_badge-exam-color-bg:                 @ax-assessment-color-00;
@_badge-task-color-text:               @ax-task-color-90;
@_badge-task-color-bg:                 @ax-task-color-00;
@_badge-event-color-text:              @ax-color-event-80;
@_badge-event-color-bg:                @ax-color-event-40;
@_badge-recommendation-color-text:     @ax-color-blue-60;
@_badge-recommendation-color-bg:       @ax-color-white;
@_badge-recommendation-border-color:   @_badge-recommendation-color-bg;
@_badge-beta-color-text:               @ax-color-blue-60;
@_badge-beta-color-bg:                 @ax-color-white;
@_badge-beta-border-color:             @_badge-beta-color-bg;
@_badge-milestone-color-text:          @ax-color-grey-80;
@_badge-milestone-color-bg:            @ax-color-grey-20;
@_badge-evaluation-color-text:         @ax-assessment-color-90;
@_badge-evaluation-color-bg:           @ax-assessment-color-00;
@_badge-validation-passed-color-text:  @ax-color-green-80;
@_badge-validation-passed-color-bg:    @green-background;
@_badge-validation-failed-color-text:  @ax-color-red-80;
@_badge-validation-failed-color-bg:    @red-background;
@_badge-validation-pending-color-text: @ax-color-grey-80;
@_badge-validation-pending-color-bg:   @ax-color-grey-30;
@_badge-module-color-text:             #035E42;
@_badge-module-color-bg:               #D6F7EF;
@_badge-module-border-color:           @_badge-module-color-bg;
@_badge-survey-color-text:             #035E42;
@_badge-survey-color-bg:               #D6F7EF;
@_badge-survey-border-color:           @_badge-survey-color-bg;
@_badge-reassigned-color-text:         #272D2A;
@_badge-reassigned-color-bg:           #EFEFEF;
@_badge-reassigned-border-color:       @_badge-reassigned-color-bg;
@_badge-link-color-text:               #105ED2;
@_badge-link-color-bg:                 #D0EEFF;
@_badge-expired-color-text:            #B43F00;
@_badge-expired-color-bg:              #FFE0B2;
@_badge-draft-color-text:              @ax-color-grey-80;
@_badge-draft-color-bg:                @ax-color-grey-30;

.ax-tag {
  .border-radius(1.2rem);
  display: inline;
  font-size: 1.2rem;
  font-weight: @ax-font-weight-medium;
  padding: .4rem .8rem;
  text-transform: uppercase;
  margin: 0;
  line-height: 2rem;

  &&--beta {
    color: @_badge-recommendation-color-text;
    background-color: @_badge-recommendation-color-bg;
    border: 1px solid @_badge-recommendation-color-text;
    border-radius: 2rem;
    padding: .2rem .8rem;
    font-size: 0.9rem;
    text-transform: none;
  }

  &&--recommendation {
    background-color: @_badge-recommendation-color-bg;
    color: @_badge-recommendation-color-text;
  }

  &&--topic {
    background-color: @_badge-topic-color-bg;
    color: @_badge-topic-color-text;
  }

  &&--certification {
    background-color: @_badge-certification-color-bg;
    color: @_badge-certification-color-text;
  }

  &&--exam {
    background-color: @_badge-exam-color-bg;
    color: @_badge-exam-color-text;
  }

  &&--event_enrollment,
  &&--event {
    background-color: @_badge-event-color-bg;
    color: @_badge-event-color-text;
  }

  &&--task {
    background-color: @_badge-task-color-bg;
    color: @_badge-task-color-text;
  }

  &&--assigned,
  &&--enrolled,
  &&--milestone {
    background-color: @_badge-milestone-color-bg;
    color: @_badge-milestone-color-text;
  }

  &--path {
    background-color: @_badge-path-color-bg;
    color: @_badge-path-color-text;
  }

  &&--evaluation {
    background: @_badge-evaluation-color-bg;
    color: @_badge-evaluation-color-text;
  }

  &&[class*="validation-"] {
    text-transform: none;
    font-size: 1.4rem;
    line-height: 1.2rem;
  }

  &&--validation-passed {
    background: @_badge-validation-passed-color-bg;
    color: @_badge-validation-passed-color-text;
  }

  &&--validation-failed {
    background: @_badge-validation-failed-color-bg;
    color: @_badge-validation-failed-color-text;
  }

  &&--validation-pending {
    background: @_badge-validation-pending-color-bg;
    color: @_badge-validation-pending-color-text;
  }

  &&--export-type {
    text-transform: none;
    background-color: @ax-color-grey-20;
    color: @ax-color-grey-70;
  }

  [class*="icon-"] {
    font-size: 1.5rem;
    margin-right: 0.3rem;
  }

  &&--module {
    background-color: @_badge-module-color-bg;
    color: @_badge-module-color-text;
  }

  &&--survey {
    background-color: @_badge-survey-color-bg;
    color: @_badge-survey-color-text;
  }

  &&--reassigned {
    background-color: @_badge-reassigned-color-bg;
    color: @_badge-reassigned-color-text;
  }

  &&--link {
    background-color: @_badge-link-color-bg;
    color: @_badge-link-color-text;
  }

  &&-event {
    background-color: @_badge-event-color-bg;
    color: @_badge-event-color-text;
  }

  &&-expired {
    background-color: @_badge-expired-color-bg;
    color: @_badge-expired-color-text;
  }

  &&-draft {
    background-color: @_badge-draft-color-bg;
    color: @_badge-draft-color-text;
  }
}

:root {
  --ax-tag-border-color:                  @ax-color-grey-40;
  --ax-tag-topic-color-text:              @_badge-topic-color-text;
  --ax-tag-topic-color-bg:                @_badge-topic-color-bg;
  --ax-tag-path-color-text:               @_badge-path-color-text;
  --ax-tag-path-color-bg:                 @_badge-path-color-bg;
  --ax-tag-certification-color-text:      @_badge-certification-color-text;
  --ax-tag-certification-color-bg:        @_badge-certification-color-bg;
  --ax-tag-exam-color-text:               @_badge-exam-color-text;
  --ax-tag-exam-color-bg:                 @_badge-exam-color-bg;
  --ax-tag-task-color-text:               @_badge-task-color-text;
  --ax-tag-task-color-bg:                 @_badge-task-color-bg;
  --ax-tag-event-color-text:              @_badge-event-color-text;
  --ax-tag-event-color-bg:                @_badge-event-color-bg;
  --ax-tag-beta-color-text:               @_badge-beta-color-text;
  --ax-tag-beta-color-bg:                 @_badge-beta-color-bg;
  --ax-tag-beta-border-color:             @_badge-beta-border-color;
  --ax-tag-recommendation-color-text:     @_badge-recommendation-color-text;
  --ax-tag-recommendation-color-bg:       @_badge-recommendation-color-bg;
  --ax-tag-recommendation-border-color:   @_badge-recommendation-border-color;
  --ax-tag-milestone-color-text:          @_badge-milestone-color-text;
  --ax-tag-milestone-color-bg:            @_badge-milestone-color-bg;
  --ax-tag-evaluation-color-text:         @_badge-evaluation-color-text;
  --ax-tag-evaluation-color-bg:           @_badge-evaluation-color-bg;
  --ax-tag-validation-passed-color-text:  @_badge-validation-passed-color-text;
  --ax-tag-validation-passed-color-bg:    @_badge-validation-passed-color-bg;
  --ax-tag-validation-failed-color-text:  @_badge-validation-failed-color-text;
  --ax-tag-validation-failed-color-bg:    @_badge-validation-failed-color-bg;
  --ax-tag-validation-pending-color-text: @_badge-validation-pending-color-text;
  --ax-tag-validation-pending-color-bg:   @_badge-validation-pending-color-bg;
  --ax-tag-article-color-text:            @ax-color-article-80;
  --ax-tag-article-color-bg:              @ax-color-article-40;
  --ax-tag-link-color-text:               @_badge-link-color-text;
  --ax-tag-link-color-bg:                 @_badge-link-color-bg;
  --ax-tag-question-color-text:           @ax-color-question-80;
  --ax-tag-question-color-bg:             @ax-color-question-40;
  --ax-tag-published-color-text:          @ax-color-green-100;
  --ax-tag-published-color-bg:            @ax-color-green-40;
  --ax-tag-in-review-color-text:          @ax-color-blue-100;
  --ax-tag-in-review-color-bg:            @ax-color-blue-40;
  --ax-tag-reported-color-text:           @ax-color-red-100;
  --ax-tag-reported-color-bg:             @red-background;
  --ax-tag-module-color-text:             @_badge-module-color-text;
  --ax-tag-module-color-bg:               @_badge-module-color-bg;
  --ax-tag-module-border-color:           @_badge-module-border-color;
  --ax-tag-survey-color-text:             @_badge-survey-color-text;
  --ax-tag-survey-color-bg:               @_badge-survey-color-bg;
  --ax-tag-survey-border-color:           @_badge-survey-border-color;
  --ax-tag-reassigned-color-text:         @_badge-reassigned-color-text;
  --ax-tag-reassigned-color-bg:           @_badge-reassigned-color-bg;
  --ax-tag-reassigned-border-color:       @_badge-reassigned-border-color;
  --ax-tag-expired-color-text:            @_badge-expired-color-text;
  --ax-tag-expired-color-bg:              @_badge-expired-color-bg;
  --ax-tag-draft-color-text:              @_badge-draft-color-text;
  --ax-tag-draft-color-bg:                @_badge-draft-color-bg;
  --ax-tag-draft-border-color:            @_badge-draft-color-bg;
}
